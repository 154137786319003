<template>
  <div class="item-features">
    <div class="row text-center">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="AwardIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            100% Orjinal
          </h4>
          <p class="card-text">
            Ürünler tamamen orjinal olup. PTT Avm güvencesindedir
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ClockIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            10 Gün iade süreci
          </h4>
          <p class="card-text">
            Beğenmediğiniz taktirde sorgusuz iade hakkınız bulunmaktadır.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ShieldIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            2 Yıl Garanti
          </h4>
          <p class="card-text">
            Ürünler marka garantili olarak satışı yapılmaktadır.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
